import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CartService } from "../../Core/Services/Cart/cart-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-post-order",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./post-order.component.html",
  styleUrl: "./post-order.component.scss",
})
export class PostOrderComponent {
  constructor(private cartservice: CartService, private router: Router) {
    if (!cartservice.order_success) {
      this.router.navigate(["/"]);
    }
    cartservice.order_success = false;
  }
}
